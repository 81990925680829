var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.formData.sign == "add"
                      ? _vm.$t("ADD AGENT PO")
                      : _vm.$t("EDIT AGENT PO")
                  )
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              { staticClass: "Info" },
              [
                _c(
                  "el-form",
                  {
                    ref: "from",
                    attrs: {
                      model: _vm.formData,
                      "label-position": "right",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("BRAND DEALER"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("BRAND DEALER"),
                                      prop: "brandBusinessId",
                                      "label-width": "120px",
                                      required: true,
                                      rules:
                                        _vm.formData.fromaDataRules
                                          .brandBusinessId
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        on: {
                                          change:
                                            _vm.selectChangedbrandBusinessId
                                        },
                                        model: {
                                          value: _vm.formData.brandBusinessId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "brandBusinessId",
                                              $$v
                                            )
                                          },
                                          expression: "formData.brandBusinessId"
                                        }
                                      },
                                      _vm._l(
                                        this.formData.brandBusinessData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.key,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("VendorSection"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("VendorSection"),
                                      prop: "vendorvection",
                                      "label-width": "120px",
                                      required: true,
                                      rules:
                                        _vm.formData.fromaDataRules
                                          .vendorvection
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          "v-bind:disabled":
                                            _vm.formData.diasabledInput,
                                          clearable: "true"
                                        },
                                        on: { change: _vm.selectChanged },
                                        model: {
                                          value: _vm.formData.vendorvection,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "vendorvection",
                                              $$v
                                            )
                                          },
                                          expression: "formData.vendorvection"
                                        }
                                      },
                                      _vm._l(
                                        this.formData.vendorvectionData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.key,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Brand"),
                                  prop: "brand",
                                  "label-width": "120px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.brand
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "",
                                      clearable: "true"
                                    },
                                    on: { change: _vm.selectbrand },
                                    model: {
                                      value: _vm.formData.brand,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "brand", $$v)
                                      },
                                      expression: "formData.brand"
                                    }
                                  },
                                  _vm._l(_vm.formData.dictData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Division"),
                                  prop: "division",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "",
                                      clearable: "true"
                                    },
                                    model: {
                                      value: _vm.formData.division,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "division", $$v)
                                      },
                                      expression: "formData.division"
                                    }
                                  },
                                  _vm._l(_vm.formData.departData, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("COUNTRY OF DESTINATION"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("COUNTRY OF DESTINATION"),
                                      prop: "destinationCountry",
                                      required: true,
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        model: {
                                          value:
                                            _vm.formData.destinationCountry,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "destinationCountry",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.destinationCountry"
                                        }
                                      },
                                      _vm._l(
                                        _vm.formData.destinationCountryData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("BATCH"),
                                  prop: "batch",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", clearable: "true" },
                                  model: {
                                    value: _vm.formData.batch,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "batch", $$v)
                                    },
                                    expression: "formData.batch"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("PO"),
                                  prop: "po",
                                  "label-width": "120px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.po
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", clearable: "true" },
                                  model: {
                                    value: _vm.formData.po,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "po", $$v)
                                    },
                                    expression: "formData.po"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("ORDER DATE"),
                                  prop: "orderDate",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.orderDate,
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: " ",
                                    clearable: "true"
                                  },
                                  model: {
                                    value: _vm.formData.orderDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "orderDate", $$v)
                                    },
                                    expression: "formData.orderDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("RelatedContract"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RelatedContract"),
                                      prop: "contractNo",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "",
                                          clearable: "true"
                                        },
                                        on: { change: _vm.contractNochchange },
                                        model: {
                                          value: _vm.formData.contractNo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "contractNo",
                                              $$v
                                            )
                                          },
                                          expression: "formData.contractNo"
                                        }
                                      },
                                      _vm._l(
                                        _vm.formData.ContractData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("REMARK"),
                                  prop: "remark",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64" },
                                  model: {
                                    value: _vm.formData.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "remark", $$v)
                                    },
                                    expression: "formData.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("Order Infomation")))
                    ]),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.formData.tableData,
                          "row-class-name": _vm.tableRowClassName,
                          "header-cell-class-name": _vm.starAdd
                        },
                        on: { "current-change": _vm.handleCurrentChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px"
                          }
                        }),
                        _vm.formData.show
                          ? _c("el-table-column", [
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Id))]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.scope.row.OriginalNumber))
                              ])
                            ])
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("BUSINESS SERIAL NUMBER INTERNAL"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.BusinessNoIn))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".BusinessNoIn"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              disabled: true,
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.BusinessNoIn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "BusinessNoIn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.BusinessNoIn"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.BusinessNoOut))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".BusinessNoOut",
                                        rules:
                                          _vm.formData.fromaDataRules
                                            .BusinessNoOut
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "true" },
                                            model: {
                                              value: scope.row.BusinessNoOut,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "BusinessNoOut",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.BusinessNoOut"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("ProductCode"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Code))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Code",
                                        rules: _vm.formData.fromaDataRules.Code
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-autocomplete", {
                                            attrs: {
                                              "fetch-suggestions": _vm.pcSearch,
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Code,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Code", $$v)
                                              },
                                              expression: "scope.row.Code"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Product Name"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.ProductName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".ProductName"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-autocomplete", {
                                            attrs: {
                                              "fetch-suggestions": _vm.pnSearch,
                                              clearable: "true"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.MGchange(scope.row)
                                              }
                                            },
                                            model: {
                                              value: scope.row.ProductName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "ProductName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.ProductName"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("BATCH"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Batch))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Batch"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              maxlength: "64",
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Batch,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Batch",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Batch"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("COLOR"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Color))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Color"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              maxlength: "64",
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Color,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Color",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Color"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("NUMBER"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Number))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Number",
                                        rules:
                                          _vm.formData.fromaDataRules.Number
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input-number", {
                                            staticStyle: {
                                              width: "calc(100% - 20px)"
                                            },
                                            attrs: {
                                              controls: false,
                                              clearable: "",
                                              min: 0
                                            },
                                            model: {
                                              value: scope.row.Number,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Number",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Number"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("UNIT"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.UnitName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".UnitId",
                                        rules:
                                          _vm.formData.fromaDataRules.UnitId
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                "value-key": "id",
                                                filterable: "",
                                                placeholder: " ",
                                                clearable: "true"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.unitchange(
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.UnitId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "UnitId",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.UnitId"
                                              }
                                            },
                                            _vm._l(
                                              _vm.formData.unitData,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _vm.formData.LastSection
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("FIBER TYPE"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.RawFiberTypeName
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "tableData." +
                                                scope.$index +
                                                ".RawFiberTypeId",
                                              rules:
                                                _vm.formData.fromaDataRules
                                                  .RawFiberTypeId
                                            }
                                          },
                                          [
                                            scope.row.isEgdit
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      "value-key": "id",
                                                      filterable: "",
                                                      placeholder: " ",
                                                      clearable: "true"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.rawFiberchange(
                                                          scope.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .RawFiberTypeId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "RawFiberTypeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.RawFiberTypeId"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.formData.rawFiberData,
                                                    function(item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                590863242
                              )
                            })
                          : _vm._e(),
                        _vm.formData.LastSection
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("ORIGIN"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        !scope.row.isEgdit
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.VendorAddressName
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "tableData." +
                                                scope.$index +
                                                ".VendorAddressId",
                                              rules:
                                                _vm.formData.fromaDataRules
                                                  .VendorAddressId
                                            }
                                          },
                                          [
                                            scope.row.isEgdit
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      "value-key": "id",
                                                      filterable: "",
                                                      placeholder: " ",
                                                      clearable: "true"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.vendorAddchange(
                                                          scope.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .VendorAddressId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "VendorAddressId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.VendorAddressId"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.formData.vendorAddData,
                                                    function(item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2195710314
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("REMARK"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Remark))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Remark"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "true" },
                                            model: {
                                              value: scope.row.Remark,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Remark",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Remark"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        !_vm.isLocked
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("OPERATION"),
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            title: _vm.$t("Delete"),
                                            type: "danger",
                                            size: "small",
                                            icon: "el-icon-delete",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3242673147
                              )
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        !_vm.isLocked
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("CANCEL")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: _vm.$t("ContractNo"),
                          visible: _vm.ContractNoDialogTableVisible,
                          "modal-append-to-body": false,
                          width: "30%",
                          top: "2vh"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.ContractNoDialogTableVisible = $event
                          }
                        }
                      },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ContractNorules",
                            attrs: {
                              model: _vm.ContractNoEdit,
                              rules: _vm.ContractNorules,
                              "label-position": "top",
                              "label-width": "150px"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24, sm: 24, md: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("ContractNo"),
                                          required: true,
                                          prop: "ContractNo",
                                          rules: _vm.ContractNorules.ContractNo
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "calc(100% - 115px)"
                                          },
                                          attrs: { clearable: "true" },
                                          model: {
                                            value:
                                              _vm.ContractNoEdit.ContractNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ContractNoEdit,
                                                "ContractNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ContractNoEdit.ContractNo"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  padding: "20px 0"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitContractNo(
                                          "ContractNorules"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Yes")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.ContractNoDialogTableVisible = false
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Cancel")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }