<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{  this.formData.sign=='add'? $t('ADD AGENT PO'):$t('EDIT AGENT PO') }}</div>
            </div>
            <template>
                <div class="Info">
                    <el-form :model="formData" ref="from" label-position="right" v-bind:show-message="notip">
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('BRAND DEALER')" placement="top-start">
                                    <el-form-item :label="$t('BRAND DEALER')" prop="brandBusinessId" label-width="120px" :required="true" :rules="formData.fromaDataRules.brandBusinessId">
                                        <el-select v-model="formData.brandBusinessId" filterable style="width: calc(100% - 115px);" placeholder="" @change="selectChangedbrandBusinessId" clearable="true">
                                            <el-option v-for="item in this.formData.brandBusinessData"
                                                       :key="item.key"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('VendorSection')" placement="top-start">
                                    <el-form-item :label="$t('VendorSection')" prop="vendorvection" label-width="120px" :required="true" :rules="formData.fromaDataRules.vendorvection">
                                        <el-select v-model="formData.vendorvection" filterable style="width: calc(100% - 115px);" placeholder="" @change="selectChanged" :v-bind:disabled="formData.diasabledInput" clearable="true">
                                            <el-option v-for="item in this.formData.vendorvectionData"
                                                       :key="item.key"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Brand')" prop="brand" label-width="120px" :required="true" :rules="formData.fromaDataRules.brand">
                                    <el-select v-model="formData.brand" filterable placeholder="" style="width: calc(100% - 115px);" @change="selectbrand" clearable="true">
                                        <el-option v-for="item in formData.dictData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('Division')" prop="division" label-width="120px">
                                    <el-select v-model="formData.division" filterable placeholder="" style="width: calc(100% - 115px);" clearable="true">
                                        <el-option v-for="item in formData.departData"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('COUNTRY OF DESTINATION')" placement="top-start">
                                    <el-form-item :label="$t('COUNTRY OF DESTINATION')" prop="destinationCountry" :required="true" label-width="120px">
                                        <el-select v-model="formData.destinationCountry" filterable placeholder="" style="width: calc(100% - 115px);" clearable="true">
                                            <el-option v-for="item in formData.destinationCountryData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('BATCH')" prop="batch" label-width="120px">
                                    <el-input v-model="formData.batch" style="width: calc(100% - 115px);" maxlength="64" clearable="true"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('PO')" prop="po" label-width="120px" :required="true" :rules="formData.fromaDataRules.po">
                                    <el-input v-model="formData.po" style="width: calc(100% - 115px);" maxlength="64" clearable="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('ORDER DATE')" prop="orderDate" :required="true" :rules="formData.fromaDataRules.orderDate" label-width="120px">
                                    <el-date-picker v-model="formData.orderDate" style="width: calc(100% - 115px);"
                                                    type="date" value-format="yyyy-MM-dd"
                                                    placeholder=" " clearable="true">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :sm="24" :md="12">
                                <el-tooltip class="item" effect="dark" :content="$t('RelatedContract')" placement="top-start">
                                    <el-form-item :label="$t('RelatedContract')" prop="contractNo" label-width="120px">
                                        <el-select v-model="formData.contractNo" filterable style="width: calc(100% - 115px);" placeholder="" @change="contractNochchange" clearable="true">
                                            <el-option v-for="item in formData.ContractData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>

                            <el-col :span="12" :sm="24" :md="12">
                                <el-form-item :label="$t('REMARK')" prop="remark" label-width="120px">
                                    <el-input v-model="formData.remark" style="width: calc(100% - 115px);" maxlength="64"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="smallTit">{{$t('Order Infomation')}}</div>
                        <el-table :data="formData.tableData" :row-class-name="tableRowClassName"
                                  @current-change="handleCurrentChange" :header-cell-class-name="starAdd">
                            <el-table-column :label="$t('Index')" type="index" width="50px">
                            </el-table-column>
                            <el-table-column v-if="formData.show">
                                <span>{{scope.row.Id}}</span>
                                <span>{{scope.row.OriginalNumber}}</span>
                            </el-table-column>
                            <el-table-column :label="$t('BUSINESS SERIAL NUMBER INTERNAL')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.BusinessNoIn}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.BusinessNoIn'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.BusinessNoIn" :disabled="true" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('BUSINESS SERIAL NUMBER EXTERNAL')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.BusinessNoOut}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.BusinessNoOut'" :rules="formData.fromaDataRules.BusinessNoOut" >
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.BusinessNoOut" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('ProductCode')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Code}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Code'" :rules="formData.fromaDataRules.Code">
                                        <el-autocomplete v-if="scope.row.isEgdit" v-model="scope.row.Code"
                                                         :fetch-suggestions="pcSearch" clearable="true"></el-autocomplete>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Product Name')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.ProductName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.ProductName'">
                                        <el-autocomplete v-if="scope.row.isEgdit" v-model="scope.row.ProductName"
                                                         :fetch-suggestions="pnSearch"
                                                         v-on:change="MGchange(scope.row)" clearable="true"></el-autocomplete>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('BATCH')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Batch}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Batch'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Batch" clearable maxlength="64" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('COLOR')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Color}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Color'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Color" clearable maxlength="64" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('NUMBER')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Number}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Number'" :rules="formData.fromaDataRules.Number">
                                        <el-input-number v-if="scope.row.isEgdit" v-model="scope.row.Number" :controls="false" clearable :min="0" style="width: calc(100% - 20px);"></el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('UNIT')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.UnitName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.UnitId'" :rules="formData.fromaDataRules.UnitId">
                                        <el-select v-if="scope.row.isEgdit" value-key="id" v-model="scope.row.UnitId" filterable placeholder=" " v-on:change="unitchange(scope.row)" clearable="true">
                                            <el-option v-for="item in formData.unitData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <!--<el-table-column :label="$t('Ingredient')" align='center' v-if="!formData.LastSection">
            <template slot-scope="scope">
                <span v-if="!scope.row.isEgdit">{{scope.row.Ingredient}}</span>
                <el-form-item :prop="'tableData.' + scope.$index + '.Ingredient'">
                    <el-input v-if="scope.row.isEgdit" v-model="scope.row.Ingredient" clearable maxlength="64"></el-input>
                </el-form-item>
            </template>
        </el-table-column>-->
                            <el-table-column :label="$t('FIBER TYPE')" align='center' v-if="formData.LastSection">
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.RawFiberTypeName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.RawFiberTypeId'" :rules="formData.fromaDataRules.RawFiberTypeId">
                                        <el-select v-if="scope.row.isEgdit" value-key="id" v-model="scope.row.RawFiberTypeId" filterable placeholder=" " v-on:change="rawFiberchange(scope.row)" clearable="true">
                                            <el-option v-for="item in formData.rawFiberData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('ORIGIN')" align='center' v-if="formData.LastSection">
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.VendorAddressName}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.VendorAddressId'" :rules="formData.fromaDataRules.VendorAddressId">
                                        <el-select v-if="scope.row.isEgdit" value-key="id" v-model="scope.row.VendorAddressId" filterable placeholder=" " v-on:change="vendorAddchange(scope.row)" clearable="true">
                                            <el-option v-for="item in formData.vendorAddData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('REMARK')" align='center'>
                                <template slot-scope="scope">
                                    <span v-if="!scope.row.isEgdit">{{scope.row.Remark}}</span>
                                    <el-form-item :prop="'tableData.' + scope.$index + '.Remark'">
                                        <el-input v-if="scope.row.isEgdit" v-model="scope.row.Remark" clearable="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('OPERATION')" align='center' v-if="!isLocked">
                                <template slot-scope="scope">
                                    <el-button @click="deleteItem(scope.$index,scope.row)" :title="$t('Delete')" type="danger" size="small" icon="el-icon-delete" circle></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" @click="submitForm" v-if="!isLocked">{{$t('Save')}}</el-button>
                            <el-button @click="resetForm">{{$t('CANCEL')}}</el-button>
                        </div>
                        <el-dialog :title="$t('ContractNo')" :visible.sync="ContractNoDialogTableVisible" :modal-append-to-body="false" width="30%" top="2vh">
                            <el-form :model="ContractNoEdit" ref="ContractNorules" :rules="ContractNorules" label-position="top" label-width="150px">
                                <el-row>
                                    <el-col :span="24" :sm="24" :md="24">
                                        <el-form-item :label="$t('ContractNo')" :required="true" prop="ContractNo" :rules="ContractNorules.ContractNo">
                                            <el-input v-model="ContractNoEdit.ContractNo" style="width: calc(100% - 115px);" clearable="true"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div style="text-align:center;padding:20px 0;">
                                    <el-button type="primary"  @click="submitContractNo('ContractNorules');">{{$t('Yes')}}</el-button>
                                    <el-button @click="ContractNoDialogTableVisible = false">{{$t('Cancel')}}</el-button>
                                </div>
                            </el-form>
                        </el-dialog>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>

<script>
    import { purOrderList, addPOHead, editHead, addPOBody, editBody, delBody, SoListByPoDetailId, addContractHead, addContractBody} from "@/api/purchaseOrd_Agen";
    import { stockNum, checkInventory} from "@/api/salesOrd";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool,TracingStringList, Getcorpinfo, corpinfo, GetBusinessNoIn } from "@/api/unified";
    export default {
        name: 'Info',
        data() {
            var requiredbrand = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('Brand') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requireddivision = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('Division') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requireddestinationCountry = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('COUNTRY OF DESTINATION') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredsuppliertext = (rule, value, callback) => {
                if (this.formData.LastSection) {
                    if (value == '' || value == undefined || value == null) {
                        this.notiplist.push(this.$t('Supplier') + ':' + this.$t('Required'));
                        callback(new Error(this.$t('Required')));
                    } else { callback(); }
                }
                else { callback(); }
            };
            var requiredFiberType = (rule, value, callback) => {
                if (this.formData.LastSection) {
                    if (value == '' || value == undefined || value == null) {
                        let indexRule = rule.fullField.split(".")
                        this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('FIBER TYPE') + ':' + this.$t('Required'));
                        callback(new Error(this.$t('Required')));
                    } else { callback(); }
                }
                else { callback(); }
            };
            var requiredVendorAdd = (rule, value, callback) => {
                if (this.formData.LastSection) {
                    if (value == '' || value == undefined || value == null) {
                        let indexRule = rule.fullField.split(".")
                        this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('ORIGIN') + ':' + this.$t('Required'));
                        callback(new Error(this.$t('Required')));
                    } else { callback(); }
                }
                else { callback(); }
            };
            var requiredsupplier = (rule, value, callback) => {
                if (this.formData.LastSection) { callback(); }
                else {
                    if (value == '' || value == undefined || value == null) {
                        this.notiplist.push(this.$t('Supplier') + ':' + this.$t('Required'));
                        callback(new Error(this.$t('Required')));
                    } else { callback(); }
                }
            };
            var requiredbatch = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('BATCH') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredorderDate = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('ORDER DATE') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredvendorvection = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('VendorSection') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredbrandBusinessId = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('BRAND DEALER') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }
            };
            var requiredpo = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    this.notiplist.push(this.$t('PO') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var checkIsOnly = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    callback(new Error(this.$t('Required')));
                } else {
                    TracingUnifiedIsBool("ContractNoExists_AgenEntryPo", this.formData.brandBusinessId, value).then(res => {
                        var IsExist = res.data;
                        if (!IsExist) {
                            callback(new Error(this.$t('ContractNoExists')));
                        } else { callback(); }
                    }).catch((erro) => { console.log(erro) });
                }
            };
            var requiredUnit = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('UNIT') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredCode = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('ProductCode') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var requiredProductCode = (rule, value, callback) => {
                if (value == '' || value == undefined || value == null) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('Product Name') + ':' + this.$t('Required'));
                    callback(new Error(this.$t('Required')));
                } else { callback(); }

            };
            var checkKeepFourPlaces = (rule, value, callback) => {
                const FloatRegex = /^\d{1,14}(\.\d{1,4})?$/;
                if (!FloatRegex.test(value)) {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('KeepFourPlaces'));
                    callback(new Error(this.$t('KeepFourPlaces')));
                } else { callback(); }

            };
            var valiInventory = (rule, value, callback) => {
                if (this.formData.sign != 'add') {
                    let indexRule = rule.fullField.split(".")
                    var poDetailid = this.formData.tableData[indexRule[1]].Id;
                    var ONumber = this.formData.tableData[indexRule[1]].OriginalNumber;
                    if (poDetailid != '') {
                        checkInventory(poDetailid, poDetailid, value, 'poEdit').then(res => {
                            var result = res.data;
                            if (result) {
                                stockNum(poDetailid).then(resu => {
                                    var resultnum = resu.data;
                                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('UsedQuantityOfSO') + (ONumber - resultnum));
                                    callback(new Error(this.$t('UsedQuantityOfSO') + (ONumber - resultnum)));
                                });
                            } else { callback(); }
                        }).catch((erro) => { console.log(erro) });
                    }
                    else { callback(); }

                }
                else { callback(); }
            };
            var valiNumbPass = (rule, value, callback) => {
                if (Number(value) > 0) {
                    callback();
                } else {
                    let indexRule = rule.fullField.split(".")
                    this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('NUMBER') + ':' + this.$t('NeedGreater0'));
                    callback(new Error(this.$t('NeedGreater0')));
                }
            };
            var valiBusinessNoOut = (rule, value, callback) => {
                let indexRule = rule.fullField.split(".")
                if (value == '' || value == undefined || value == null) {
    
                    //this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ':' + this.$t('Required'));
                    //callback(new Error(this.$t('Required')));
                    callback(); 
                } else {
                    var poDetailid = this.formData.tableData[indexRule[1]].Id;
                    TracingUnifiedIsBool("PurchaseOrderDetail_BusinessNoOutExist", poDetailid, value).then(res => {
                        var IsExist = res.data;
                        if (!IsExist) {
                            this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ':' + this.$t('Exist'));
                            callback(new Error(this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('Exist')));
                        } else {
                            var nums = 0;
                            this.formData.tableData.forEach(v => {
                                if (v.BusinessNoOut == value)
                                {
                                    nums++;
                                }
                            })
                            if (nums >1 ) {
                                this.notiplist.push(this.$t('Index') + (parseInt(indexRule[1]) + 1) + '  ' + this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ':' + this.$t('The current list already exists'));
                                callback(new Error(this.$t('BUSINESS SERIAL NUMBER EXTERNAL') + ' ' + this.$t('The current list already exists')));
                            }
                            else { callback(); }
                        }
                    }).catch((erro) => { console.log(erro) });
                }
            };
            return {
                ContractNoDialogTableVisible: false,
                ContractNoEdit: {
                    ContractNo: '',
                },
                ContractNorules: {
                    ContractNo: [{ validator: checkIsOnly, trigger: 'blur' }],
                },
                notip: true, notipInfo: "", notiplist: [], notificationPromise: Promise.resolve(),
                isLocked: false, corpId: '', NewBusinessNoIn:'',
                formData: {
                    show: false, sign: '', LastSection: false, suppliertext: '', diasabledInput: false,
                    brandBusinessId: '',
                    isCanKey: false, contractslist: [], restaurants: [], state: '', timeout: null,
                    loadId: '', loadCorpid: '', loadSystemBatchNo: '', isAddContract: 1, addContracthtext: '',
                    brand: '', division: '', destinationCountry: '', supplier: '', batch: '', remark: '', vendorvection: '', po: '', contractNo: '', orderDate: '',
                    //隐藏id
                    vendorData: [],//供应商
                    brandBusinessData: [],//品牌商
                    unitData: [],//计量单位
                    departData: [],//部门
                    destinationCountryData:[],//销售目的国
                    productData: [],//产品
                    vendorvectionData: [],//供应商类别
                    ContractData: [],//供应商类别1
                    dictData: [],
                    rawFiberData: [],
                    vendorAddData: [],
                    tableData: [{ Id: '', Batch: '', ProductCode: '', Code: '', Color: '', Ingredient: '', Number: '', UnitId: '', Remark: '', isEgdit: true, ProductName: '', UnitName: '', RawFiberTypeName: '', VendorAddressName: '', RawFiberTypeId: '', VendorAddressId: '', BusinessNoIn: '', BusinessNoOut: ''  }],
                    fromaDataRules: {
                        brand: [{ validator: requiredbrand, trigger: 'blur' }],
                        destinationCountry: [{ validator: requireddestinationCountry, trigger: 'blur' }],
                        /*  division: [{ validator: requireddivision, trigger: 'blur' }],*/
                        suppliertext: [{ validator: requiredsuppliertext, trigger: 'blur' }],
                        supplier: [{ validator: requiredsupplier, trigger: 'blur' }],
                      /*  batch: [{ validator: requiredbatch, trigger: 'blur' }],*/
                        orderDate: [{ type: 'date', validator: requiredorderDate, trigger: 'blur' }],
                        vendorvection: [{ validator: requiredvendorvection, trigger: 'blur' }],
                    /*   addContracthtext: [{ validator: checkIsOnly, trigger: 'blur' },
                          { validator: checkIsExist, trigger: 'blur' },],*/
                        po: [{ validator: requiredpo, trigger: 'blur' }],

                        Code: [{ validator: requiredCode, trigger: 'blur' }],
                        /*ProductName: [{ validator: requiredProductCode, trigger: 'blur' }],*/
                        UnitId: [{ validator: requiredUnit, trigger: 'blur' }],
                        RawFiberTypeId: [{ validator: requiredFiberType, trigger: 'blur' }],
                        VendorAddressId: [{ validator: requiredVendorAdd, trigger: 'blur' }],
                        Number: [{ type: "number", validator: checkKeepFourPlaces, trigger: 'blur' },
                        { type: "number", validator: valiNumbPass, trigger: "blur" },
                        { type: "number", validator: valiInventory, trigger: "blur" }
                        ],
                        BusinessNoOut: [{ validator: valiBusinessNoOut, trigger: 'blur' }],
                        brandBusinessId: [{ validator: requiredbrandBusinessId, trigger: 'blur' }],
                    },

                },
                productCodeData: [],
                productNameData: [],
            }
        },
        watch: {
            $route() {
                this.init()
            }
        },// watch的作用可以监控一个值的变换,并调用因为变化需要执行的方法。 //监听路由是否发生变化
        created() {
            this.init()// 在加载的时候执行了这个方法，可以调用这个根据ID来插叙的方法
            TracingDropDownList('AgenEntryPo_BrandBusiness').then(res => {
                this.formData.brandBusinessData = res.data;
                if (this.formData.brandBusinessData.length == 1 && this.formData.sign == 'add')
                {
                    this.formData.brandBusinessId = this.formData.brandBusinessData[0].value;
                    this.selectChangedbrandBusinessId();
                }
            });

        },//加载
        methods: {//加载数据
            init() {
                this.formData.sign = this.$route.query.sign;
                this.formData.loadId = this.$route.query.ID;
                this.formData.loadCorpid = this.$route.query.corpid;
                this.formData.loadSystemBatchNo = this.$route.query.systemBatchNo;
                if (this.formData.sign == 'edit') {
                    this.getPurOrdList();
                } else {
                  //  this.getSystemBatchNum();
                }
            },    //删除数据
            deleteItem(index, row) {
                if (row.Id == '') {
                    this.formData.tableData.splice(index, 1);
                }
                else {
                SoListByPoDetailId(row.Id, this.formData.brandBusinessId).then(res => {
                    var list = res.data
                    if (list.length > 0) {
                        this.$message({ showClose: true, message: this.$t('NotCanDeletedPO'), type: "warning" });
                        return;
                    }
                    else {
                        this.$confirm(this.$t('IsDel') + row.Code, this.$t('Tips'), {
                            confirmButtonText: this.$t('Yes'),
                            cancelButtonText: this.$t('Cancel'),
                            type: "warning"
                        }).then(() => {
           
                                delBody(row.Id).then(() => {
                                    //待删除
                                    this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                                    this.formData.tableData.splice(index, 1);
                                });
                 
                        })
                    }

                })
                }
            },//点击行变化
            handleCurrentChange(currentRow, oldCurrentRow) {
                if (currentRow.index == this.formData.tableData.length - 1) {
                    this.addrow();
                }
                if (currentRow.Id == '') {
                    this.$set(currentRow, 'isEgdit', true);
                    if (oldCurrentRow != null && oldCurrentRow != undefined) {
                        this.$set(oldCurrentRow, 'isEgdit', false);
                    }
                    return;
                } else {
                    SoListByPoDetailId(currentRow.Id).then(res => {
                        var list = res.data;
                        if (list.length > 0) {
                            this.$message({ showClose: true, message: this.$t('NotCanEditPO'), type: "warning" });
                            return;
                        }
                        else {
                            this.$set(currentRow, 'isEgdit', true);
                            if (oldCurrentRow != null && oldCurrentRow != undefined) {
                                this.$set(oldCurrentRow, 'isEgdit', false);
                            }
                        }

                    });
                }

            },//提交数据
            submitForm() {
                this.$refs["from"].clearValidate() // 移除校验结果
                for (var j = this.formData.tableData.length - 1; j > -1; j--) {
                    if (this.formData.tableData[j].BusinessNoOut.trim() == '' &&this.formData.tableData[j].Batch.trim() == '' && this.formData.tableData[j].ProductCode.trim() == '' && this.formData.tableData[j].Color.trim() == '' && this.formData.tableData[j].Ingredient.trim() == ''
                        && this.formData.tableData[j].Code.trim() == '' && this.formData.tableData[j].UnitId == '' && this.formData.tableData[j].Remark.trim() == '' && (this.formData.tableData[j].Number == 0 || this.formData.tableData[j].Number == '')) {
                        this.formData.tableData.splice(j, 1);
                    }
                }
                if (this.formData.tableData.length == 0) {
                    this.addrow();
                    this.$message({
                        message: this.$t('No Data Submitted'),
                        type: "error"
                    });//提示无数据
                    return false;
                } else {
                    this.$nextTick(() => {//避免点击两次
                        this.notip = false;
                        this.notiplist = [];
                        let formName = "from";
                        this.$refs[formName].validate((valid) => {
                            if (valid) {
                                //添加合同
                                if (this.formData.contractNo == "AddContract") {
                                    let Contractheadrow = {
                                        id: '',
                                        brand: this.formData.brand.trim(),
                                        code: this.formData.addContracthtext.trim(),
                                        vendorCorporationId: "",
                                        type: '',
                                        remark: '',
                                        signDate: '1990-01-01T12:00',
                                        beginDate: '1990-01-01T12:00',
                                        endDate: '1990-01-01T12:00',
                                        status: 0,
                                        createTime: '2021-05-01T08:00',
                                        createUserid: '',
                                        updateTime: '2021-05-01T08:00',
                                        updateUserid: '',
                                        departmentId: this.formData.division,
                                        corporationId: this.formData.brandBusinessId,
                                    };
                                    addContractHead(Contractheadrow).then(res => {
                                        this.formData.contractNo = res.data.result;
                                        this.formData.tableData.forEach(v => {
                                            let paramrow = {
                                                id: '',
                                                contractId: res.data.result,
                                                indexNumber: v.index,
                                                productCode: v.ProductCode,
                                                productName: v.ProductName,
                                                model: '',
                                                code: v.Code.trim(),
                                                color: v.Color.trim(),
                                                remark: v.Remark.trim(),
                                                number: v.Number,
                                                unitId: v.UnitId,
                                                status: 0,
                                                createTime: '2021-05-01T08:00',
                                                createUserid: '',
                                                updateTime: '2021-05-01T08:00',
                                                updateUserid: '',
                                                departmentId: '',
                                                corporationId: this.formData.brandBusinessId,
                                            };
                                            addContractBody(paramrow).then(() => { }).catch((erro) => { console.log(erro) });
                                        })
                                        this.savePo();
                                    }).catch((erro) => { console.log(erro) });
                                } else {
                                    this.savePo();
                                }
                            } else {
                                this.notificationPromise = this.notificationPromise.then(this.$nextTick).then(() => {
                                    let newDatas = [];
                                    const h = this.$createElement;
                                    for (let i in this.notiplist) {
                                        newDatas.push(h('p', null, this.notiplist[i]));
                                    }
                                    this.$notify.error({
                                        title: this.$t('OperationFailed'),
                                        offset: 100,
                                        showClose: true,
                                        duration: 0,
                                        message: h('div', null, newDatas),
                                        customClass: 'notifyStyle',
                                    });
                                });
                                return false;
                            }
                        });
                    });
                }
            },//保存按钮
            savePo() {
                let headrow = {
                    id: this.formData.loadId,
                    brand: this.formData.brand.trim(),
                    batch: this.formData.batch.trim(),
                    po: this.formData.po.trim(),
                    contractId: this.formData.contractNo.trim(),
                    vendorSectionId: this.formData.vendorvection,
                    vendorCorporationId: "",
                    systemBatchNo: this.formData.loadSystemBatchNo,
                    remark: this.formData.remark.trim(),
                    status: 0,
                    orderDate: this.formData.orderDate,
                    createTime: '2021-05-01T08:00',
                    createUserid: '',
                    updateTime: '2021-05-01T08:00',
                    updateUserid: '',
                    departmentId: this.formData.division,
                    destinationCountry: this.formData.destinationCountry,
                    corporationId: this.formData.brandBusinessId,
                };
                if (this.formData.loadId != '') {
                    editHead(headrow).then(() => {
                        this.formData.tableData.forEach(v => {
                            let paramrow = {
                                id: v.Id,
                                purchaseOrderId: this.formData.loadId,
                                indexNumber: v.index,
                                batch: v.Batch,
                                productCode: v.ProductCode,
                                productName: v.ProductName,
                                code: v.Code.trim(),
                                model: '',
                                color: v.Color.trim(),
                                ingredient: v.Ingredient.trim(),
                                remark: v.Remark.trim(),
                                number: v.Number,
                                unitId: v.UnitId,
                                rawFiberTypeId: v.RawFiberTypeId,
                                vendorAddressId: v.VendorAddressId,
                                businessNoIn: v.BusinessNoIn,
                                businessNoOut: v.BusinessNoOut,
                                status: 0,
                                createTime: '2021-05-01T08:00',
                                createUserid: '',
                                updateTime: '2021-05-01T08:00',
                                updateUserid: '',
                                departmentId: this.formData.division,
                                corporationId: this.formData.brandBusinessId,
                            };
                            if (v.Id != '') {
                                editBody(paramrow).then().catch((erro) => { console.log(erro) });
                            }
                            if (v.Id == '') {
                                addPOBody(paramrow).then().catch((erro) => { console.log(erro) });
                            }
                        })
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });

                        //返回列表
                        this.$router.push({ path: '/pvh_factory/salesOrd' });
                    }).catch((erro) => { console.log(erro) });
                }
                if (this.formData.loadId == '') {
                    addPOHead(headrow).then(res => {
                        this.formData.tableData.forEach(v => {
                            let paramrow = {
                                id: v.Id,
                                purchaseOrderId: res.data.result,
                                indexNumber: v.index,
                                batch: v.Batch,
                                productCode: v.ProductCode,
                                productName: v.ProductName,
                                code: v.Code.trim(),
                                model: '',
                                color: v.Color.trim(),
                                ingredient: v.Ingredient.trim(),
                                remark: v.Remark.trim(),
                                number: v.Number,
                                unitId: v.UnitId,
                                rawFiberTypeId: v.RawFiberTypeId,
                                vendorAddressId: v.VendorAddressId,
                                businessNoIn: v.BusinessNoIn,
                                businessNoOut: v.BusinessNoOut,
                                status: 0,
                                createTime: '2021-05-01T08:00',
                                createUserid: '',
                                updateTime: '2021-05-01T08:00',
                                updateUserid: '',
                                departmentId: this.formData.division,
                                corporationId: this.formData.brandBusinessId,
                            };
                            if (v.Id != '') {
                                editBody(paramrow).then().catch((erro) => { console.log(erro) });
                            }
                            if (v.Id == '') {
                                addPOBody(paramrow).then().catch((erro) => { console.log(erro) });
                            }
                        })
                        this.$message({
                            message: this.$t('OperationSuccess'),
                            type: "success"
                        });
                        //返回列表
                        setTimeout(() => {
                            this.$router.push({ path: '/pvh_factory/salesOrd' });
                        }, 1000 * Math.random());
                    }).catch((erro) => { console.log(erro) });
                }
            },//保存订单信息
            resetForm() {
                this.$router.push({ path: '/pvh_factory/salesOrd' });
            },//取消按钮
            getSystemBatchNum() {
                corpinfo().then(res => {
                    var corp = res.data.code;
                    if (corp.length > 7) {
                        corp = corp.substring(0, 7);
                    }
                    const year = new Date().getFullYear(); //4位
                    const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);//2位
                    const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();//2位
                    const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();//2位
                    const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();//2位
                    const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();//2位
                    const ms = new Date().getMilliseconds();//3位
                    var CurrentDate = year + month + date + hh + mm + mm + ss + ms + Math.floor(Math.random() * 10000)//4位随机数 共21位;
                    this.formData.loadSystemBatchNo = corp.padStart(7, '0') + CurrentDate
                });
            },//系统内批次号
            starAdd(obj) {
                if (this.formData.LastSection) {
                    if (obj.columnIndex ===3 || obj.columnIndex === 7 || obj.columnIndex === 8 || obj.columnIndex === 9 || obj.columnIndex === 10) {
                        return 'star';
                    }
                }
                else {
                    if ( obj.columnIndex === 3 || obj.columnIndex === 7 || obj.columnIndex === 8) {
                        return 'star';
                    }
                }
            },//列头加星号
            tableRowClassName({ row, rowIndex }) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },//加index
            addrow() {
                GetBusinessNoIn("PVH_System_order_business", this.corpId).then(res => {
                    this.NewBusinessNoIn = res.data;
                    let item = { Id: '', Batch: '', ProductCode: '', Code: '', Color: '', Ingredient: '', Number: '', UnitId: '', Remark: '', isEgdit: false, ProductName: '', UnitName: '', RawFiberTypeName: '', VendorAddressName: '', RawFiberTypeId: '', VendorAddressId: '', BusinessNoIn: this.NewBusinessNoIn, BusinessNoOut: '' }
                    this.formData.tableData.push(item);      // 把每一行的索引放进row
                })
            },//新增行
            GetNewBusinessNoIn() {
                GetBusinessNoIn("PVH_System_order_business", this.corpId).then(res => {
                    this.NewBusinessNoIn = res.data;
                    this.formData.tableData[0].BusinessNoIn = this.NewBusinessNoIn;
                })
            },
            async getPurOrdList() {
                await purOrderList(1, 50, '', '', '', '', '', '', '', this.formData.loadId, '', this.formData.loadCorpid).then(res => {
                    this.formData.tableData = [];
                    var infodata = res.data.PurchaseOrders;
                    this.formData.brandBusinessId = infodata[0].CorporationId;
                    this.selectChangedbrandBusinessId();
                    this.selectChanged();
                    this.formData.po = infodata[0].Po;
                    this.formData.contractNo = infodata[0].ContractId;
                    this.formData.brand = infodata[0].Brand;
                    this.formData.orderDate = infodata[0].OrderDate;
                    this.formData.remark = infodata[0].Remark;
                    this.formData.vendorvection = infodata[0].VendorSectionId;
                    this.formData.division = infodata[0].DepartmentId;
                    this.formData.destinationCountry = infodata[0].DestinationCountry;
                    this.formData.batch = infodata[0].Batch;
                    this.formData.tableData = infodata[0].Details;
                    this.formData.addContracthtext = '';
                    var ApproveStatus = infodata[0].ApproveStatus;
                    var LockedStatus = infodata[0].LockedStatus;
                    if (ApproveStatus == 100 || ApproveStatus == 50 || LockedStatus == 1) {
                        this.isLocked = true;
                    }
                    //if (this.formData.contractNo == '') {
                    //    this.formData.isAddContract = 1;
                    //}
                    //else {
                    //    this.formData.isAddContract = 2;
                    //}
                    //控制最后一级的供应商是下拉还是录入
                    TracingUnifiedIsBool("NotLastSection", this.formData.vendorvection).then(res => {
                        var NotLastSection = res.data;
                        if (!NotLastSection) {
                            this.formData.LastSection = true;
                            this.formData.diasabledInput = true;
                        }
                        else {
                            this.formData.LastSection = false;
                        }
                    })
                    if (this.formData.tableData.length == 0) { this.addrow(); }
                }).catch((erro) => { console.log(erro) });
            }//加载数据
            , MGchange(row) {
                var objrow = {};
                objrow = this.formData.productData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.label === row.ProductName;//筛选出匹配数据
                });
                if (objrow == undefined) {

                } else {
                    row.ProductCode = objrow.value;
                }
            },
            unitchange(row) {
                var objrow1 = {};
                objrow1 = this.formData.unitData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === row.UnitId;//筛选出匹配数据
                });
                row.UnitName = objrow1.label;
            },
            rawFiberchange(row) {
                var objrow1 = {};
                objrow1 = this.formData.rawFiberData.find((i) => {//这里的List就是上面遍历的数据源
                    return i.value === row.RawFiberTypeId;//筛选出匹配数据
                });
                row.RawFiberTypeName = objrow1.label;
            },
            selectChanged() {
                ProcessDropDownList('AgenEntryPo_UomGroup_UnitList', this.formData.brandBusinessId, this.formData.vendorvection).then(res => {
                    this.formData.unitData = res.data;
                });
            },
            contractNochchange() {
                if (this.formData.contractNo == ' ') {
                    this.formData.contractNo = '';
                    this.ContractNoDialogTableVisible = true;
                }
            },
            submitContractNo(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        TracingDropDownList('ContractList').then(res => {
                            this.formData.ContractData = res.data;
                            let newItem = { value: ' ', label: this.$t('Addone') };
                            this.formData.ContractData.unshift(newItem);
                            let newItem2 = { value: 'AddContract', label: this.ContractNoEdit.ContractNo };
                            this.formData.ContractData.unshift(newItem2);
                            this.ContractNoDialogTableVisible = false;
                            this.formData.contractNo = "AddContract";
                            this.formData.addContracthtext = this.ContractNoEdit.ContractNo;
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            selectChangedbrandBusinessId() {
                ProcessDropDownList('AgenEntryPo_ProductList', '', this.formData.brandBusinessId).then(res => {
                    this.formData.productData = res.data;
                });
                ProcessDropDownList('AgenEntryPo_DepartmentList', '', this.formData.brandBusinessId).then(res => {
                    this.formData.departData = res.data;
                });
                ProcessDropDownList('AgenEntryPo_DictList', 'brand', this.formData.brandBusinessId).then(res => {
                    this.formData.dictData = res.data;
                });
                TracingDropDownList('AgenEntryPo_ContractList', this.formData.brandBusinessId).then(res => {
                    this.formData.ContractData = res.data;
                    let newItem = { value: ' ', label: this.$t('Addone') };
                    this.formData.ContractData.unshift(newItem);
                });
                TracingDropDownList('AgenEntryPo_Vendorvection', this.formData.brandBusinessId).then(res => {
                    this.formData.vendorvectionData = res.data;
                    if (this.formData.vendorvectionData.length == 1 && this.formData.sign == 'add') {
                        this.formData.vendorvection = this.formData.vendorvectionData[0].value;
                        this.selectChanged();
                    }
                });
                TracingDropDownList('RawFiberType').then(res => {
                    this.formData.rawFiberData = res.data;
                });
                TracingDropDownList('AgenEntryPo_VendorAddress', this.formData.brandBusinessId).then(res => {
                    this.formData.vendorAddData = res.data;
                });
                TracingDropDownList('DestinationCountryData').then(res => {
                    this.formData.destinationCountryData = res.data;
                });
                
                Getcorpinfo(this.formData.brandBusinessId).then(res => {
                    this.corpId = res.data.id;
                    if (this.formData.sign == 'add') {
                        this.GetNewBusinessNoIn();
                    }
                })
                //成品编码数据源
                this.productCodeData = [];
                TracingStringList("ProductCode","agenPO", this.formData.brandBusinessId).then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.productCodeData.push({
                            value: res.data[i]
                        })
                    }
                })
                //商品名称数据源
                this.productNameData = [];
                TracingStringList("ProductName", "agenPO", this.formData.brandBusinessId).then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.productNameData.push({
                            value: res.data[i]
                        })
                    }
                })
            },
            getVendorvectionData() {

            },
            selectbrand(value) {
                if (value == ' ') {
                    this.formData.brand = '';
                    this.$router.push({ path: '/basic/dic' })
                }
            },
            switchchange() {
                if (this.formData.isAddContract == 3) {
                    this.formData.contractNo = '';
                }
                else if (this.formData.isAddContract == 2) {
                    this.formData.addContracthtext = '';
                }
                else if (this.formData.isAddContract == 1) {
                    this.formData.addContracthtext = '';
                    this.formData.contractNo = '';
                }

            },
            vendorAddchange(row) {
                if (row.VendorAddressId == ' ') {
                    row.VendorAddressId = '';
                    this.$router.push({ path: '/pvh_setting/vendorAddress' })
                }
                else {
                    var objrow1 = {};
                    objrow1 = this.formData.vendorAddData.find((i) => {//这里的List就是上面遍历的数据源
                        return i.value === row.VendorAddressId;//筛选出匹配数据
                    });
                    row.VendorAddressName = objrow1.label;
                }
            },
            pcSearch(queryString, cb) {
                var restaurants = this.productCodeData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            pnSearch(queryString, cb) {
                var restaurants = this.productNameData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
        },
    }
</script>


<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }
    .notifyStyle {
        width: 30% !important;
        background: #FFE4E1;
    }

    table th.star div::before {
        content: ' * ';
        color: red;
    }

    .ttt {
        width: 100px;
    }

    .searlable .el-checkbox__label {
        color: #8CA0B3;
        font-weight: 500;
    }

    .smallTit {
        margin: 1.3rem 0 1.25rem;
    }

    .formD {
        padding: 0 40px;
    }
</style>